export function dateFormat(timestamp: any) {
  const date = new Date(timestamp);
  const year = date.getFullYear(); // 获取年份
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 获取月份（注意：月份从 0 开始，需要加 1）
  const day = String(date.getDate()).padStart(2, "0"); // 获取日期

  const yearMonthDay = `${year}-${month}-${day}`; // 拼接成 YYYY-MM-DD 格式
  console.log(yearMonthDay); // 输出格式：2023-10-05
  return yearMonthDay;
}

export function generateFilters(data: { created_at: string | number }[]): {
  text: string;
  value: string;
  children?: any[];
}[] {
  const yearMonthMap = new Map<string, Set<string>>();

  // 遍历数据，提取年份和月份
  data.forEach((record) => {
    const date = new Date(record.created_at);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月份从 0 开始，需要加 1

    if (!yearMonthMap.has(year)) {
      yearMonthMap.set(year, new Set());
    }
    yearMonthMap.get(year)!.add(month);
  });

  // 将年份和月份组织成树形结构
  const filters: { text: string; value: string; children?: any[] }[] = [];
  yearMonthMap.forEach((months, year) => {
    const yearFilter = {
      text: year,
      value: year,
      children: Array.from(months).map((month) => ({
        text: `${year}-${month}`,
        value: `${year}-${month}`,
      })),
    };
    filters.push(yearFilter);
  });

  return filters;
}

export function generateUserFilters(data: { users: { email: string } }[]): {
  text: string;
  value: string;
}[] {
  const filters: { text: string; value: string; children?: any[] }[] = [];
  Array.from(new Set(data.map((record) => record.users.email))).forEach(
    (email) => {
      filters.push({
        text: email,
        value: email,
      });
    },
  );

  return filters;
}
