import { client } from "@/api/axios.ts";
import ExcelUploader from "@/components/ExcelUploader";
import { message } from "antd";

export function Setting() {
  const [messageApi] = message.useMessage();

  const import_handler = async (data: any[]) => {
    try {
      await client.post("/products/import", { data });
      messageApi.open({
        type: "success",
        content: "导入成功",
      });
    } catch (error) {
      console.error("Error setting setting", error);
      messageApi.open({
        type: "error",
        content: "导入出错",
      });
    }
  };

  return (
    <div className={"flex flex-col"}>
      <div className={"mb-2 text-2xl font-bold"}>
        <span>导入配件</span>
      </div>
      <ExcelUploader onChange={import_handler} />
    </div>
  );
}
