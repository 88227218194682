import { routerInfo } from "@/router/router-info.ts";
import { Layout, Menu, Button, theme, Avatar, type MenuProps } from "antd";
import { Menu as MenuIcon, User } from "lucide-react";
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = routerInfo.map((item) => {
  return {
    key: item.path,
    label: <Link to={item.path}>{item.title}</Link>,
  };
});

const AppLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (!mobile) {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [location.pathname, isMobile]);

  const handleMenuClick = ({ key }: { key: string }) => {
    navigate(key);
    if (isMobile) {
      setCollapsed(true);
    }
  };

  return (
    <Layout className="min-h-screen">
      {/* Overlay for mobile */}
      {isMobile && !collapsed && (
        <div
          className="fixed inset-0 z-40 bg-black/50"
          onClick={() => setCollapsed(true)}
        />
      )}

      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="md"
        collapsedWidth={isMobile ? 0 : 80}
        className="fixed bottom-0 left-0 top-0 z-50 h-screen overflow-y-auto"
        style={{ height: "100vh" }}
      >
        <div className="flex h-16 items-center justify-center">
          <h1
            className={`text-xl font-bold text-white ${collapsed ? "hidden" : ""}`}
          >
            管理后台
          </h1>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          items={items}
          onClick={handleMenuClick}
        />
        <Button
          className={"absolute bottom-8 left-1/2"}
          style={{ transform: "translateX(-50%)" }}
          onClick={() => {
            navigate("/login");
          }}
        >
          退出
        </Button>
      </Sider>

      <Layout className={collapsed ? "ml-0 md:ml-20" : "ml-0"}>
        <Header
          style={{ background: colorBgContainer }}
          className="fixed left-0 right-0 top-0 z-30 flex items-center justify-between px-4 shadow-sm"
        >
          <Button
            type="text"
            icon={<MenuIcon className="h-5 w-5" />}
            onClick={() => setCollapsed(!collapsed)}
            className="md:hidden"
          />
          <div className="flex items-center gap-4">
            <Avatar icon={<User />} />
            <span className="hidden md:inline">Admin User</span>
          </div>
        </Header>
        <Content className="mt-16 p-2">
          <div
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              padding: isMobile ? 4 : 24,
              height: "calc(100vh - 112px)",
            }}
            className={"overflow-y-scroll"}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
