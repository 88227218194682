import { Input, Table } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

const ManageProducts: React.FC = () => {
  const columns = [
    {
      title: "备件编号",
      dataIndex: "PartCode",
      key: "PartCode",
    },
    {
      title: "备件名称",
      dataIndex: "title_en",
      key: "title_en",
    },
    {
      title: "备件号码",
      dataIndex: "PartNo",
      key: "PartNo",
    },
    {
      title: "说明",
      dataIndex: "note",
      key: "note",
      render: (note: string) => (
        <p className="line-clamp-2 max-w-28" title={note}>
          {note}
        </p>
      ),
    },
  ];

  const navigate = useNavigate();
  const [keyword, setKeyword] = useState(""); // 搜索关键字
  const [page, setPage] = useState(1); // 当前页码
  const [pageSize, setPageSize] = useState(10); // 每页大小
  const [sortBy, setSortBy] = useState("id"); // 排序字段，默认为 'id'
  const [sortOrder, setSortOrder] = useState("asc"); // 排序方式，默认为 'asc'

  // 构建完整的 API 请求 URL
  const apiUrl = `/products?page=${page}&pageSize=${pageSize}&search=${keyword}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

  // 使用 useSWRImmutable 获取数据
  const { data } = useSWRImmutable(apiUrl);

  console.log(data);

  // 处理分页变化
  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };

  // 处理排序变化
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field) {
      setSortBy(sorter.field);
      setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
  };

  return (
    <div>
      <div className={"flex w-full items-center p-4 md:w-1/2"}>
        <span className={"w-12 shrink-0"}>搜索：</span>
        <Input.Search onSearch={setKeyword} defaultValue={keyword} allowClear />
      </div>
      <Table
        columns={columns}
        dataSource={data?.data}
        rowKey="id"
        pagination={{
          current: page,
          pageSize: pageSize,
          total: data?.totalPages || 50,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              navigate("/product_detail/" + record.id);
            },
          };
        }}
      />
    </div>
  );
};

export default ManageProducts;
