import { Table } from "antd";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export function Detail() {
  const { id } = useParams();
  const { data, error, isLoading } = useSWR("/product_order/" + id);

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  return <DetailCard data={data} />;
}

export function DetailCard(props: any) {
  const { data } = props;
  return (
    <div className={"flex flex-col gap-2 p-2"}>
      <div className={"flex flex-col gap-2"}>
        <div className={"flex gap-2"}>
          <div className={"rounded border p-2"}>
            <span>订单id：</span>
            <span>{data.id}</span>
          </div>
          <div className={"rounded border p-2"}>
            <span>用户：</span>
            <span>{data.users.email}</span>
          </div>
          <div className={"rounded border p-2"}>
            <span>创建时间：</span>
            <span>{new Date(data.created_at).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
      <div>
        <div className={"rounded border"}>
          <Table
            rowKey="id"
            columns={[
              {
                title: "备件编号",
                dataIndex: ["products", "PartCode"],
                key: "PartCode",
              },
              {
                title: "备件名称",
                dataIndex: ["products", "title"],
                key: "products.title",
              },
              {
                title: "备件号码",
                dataIndex: ["products", "PartNo"],
                key: "PartNo",
              },
              {
                title: "参考号码",
                dataIndex: ["products", "RefNo"],
                key: "RefNo",
              },
              {
                title: "数量",
                dataIndex: ["count"],
                key: "count",
              },
            ]}
            dataSource={data.product_order_item}
            pagination={false}
          />
        </div>
      </div>
      <div>
        <div className={"flex"}>
          <div className={"rounded border p-2"}>
            <span>备注信息：</span>
            <span>{data.txt}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
