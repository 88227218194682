import { TableWithData } from "@/components/TableWithData.tsx";
import cls from "classnames";
import { useNavigate } from "react-router-dom";

const roleMap: { [key: string]: string } = {
  ADMIN: "管理员",
  USER: "员工",
  CUSTOMER: "用户",
};

const Users: React.FC = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "role",
      dataIndex: "role",
      key: "role",
      render: (role: string) => (
        <span
          className={cls(
            "max-w-28 rounded border-2 px-1 font-bold",
            {
              "border-indigo-300": role === "ADMIN",
              "border-yellow-500": role === "USER",
              "border-zinc-600": role === "CUSTOMER",
            },
            {
              "text-indigo-300": role === "ADMIN",
              "text-yellow-500": role === "USER",
              "text-zinc-600": role === "CUSTOMER",
            },
          )}
        >
          {roleMap[role]}
        </span>
      ),
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "注册时间",
      dataIndex: "registe_time",
      key: "registe_time",
      render: (created_at: string) => (
        <p className="line-clamp-2 max-w-28">
          {new Date(created_at).toLocaleDateString()}
        </p>
      ),
    },
    {
      title: "操作",
      dataIndex: "role",
      key: "actions",
      render: (_role: string, record: any) => (
        <p className="line-clamp-2 max-w-28">
          <button
            className={"rounded border border-black px-2"}
            onClick={() => navigate(`/users/${record.id}/edit`)}
          >
            修改
          </button>
        </p>
      ),
    },
  ];

  return (
    <div>
      <div className={"mb-2 flex gap-2"}>
        <button
          className={"rounded border border-black px-2"}
          onClick={() => navigate(`/users/add`)}
        >
          添加员工
        </button>
      </div>
      <TableWithData columns={columns} dataSourceUrl="/users" title="用户" />
    </div>
  );
};

export default Users;
