import { TableWithData } from "@/components/TableWithData.tsx";
import useMobile from "@/hooks/useMobile.tsx";
import {
  dateFormat,
  generateFilters,
  generateUserFilters,
} from "@/utils/utils.ts";
import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

const ProductOrder: React.FC = () => {
  const { isMobile } = useMobile();
  const { data, isLoading, error } = useSWR("/product_order/admin");

  const columns = [
    {
      title: "编号",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "用户",
      dataIndex: ["users", "email"],
      key: "user",
      filters: generateUserFilters(data || []),
      onFilter: (value: any, record: any) =>
        record.users.email.indexOf(value as string) === 0,
    },
    {
      title: "下单时间",
      dataIndex: "created_at",
      key: "created_at",
      width: 170,
      render: (created_at: string) => (
        <p className="line-clamp-2 max-w-28">{dateFormat(created_at)}</p>
      ),
      filters: generateFilters(data || []),
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value: any, record: any) => {
        const date = new Date(record.created_at);
        const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;
        return yearMonth === value;
      },
    },
    {
      title: "备注信息",
      dataIndex: "txt",
      key: "txt",
    },
  ];

  const navigate = useNavigate();

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  const toDetail = (id: any) => {
    navigate("/detail/" + id);
  };

  if (isMobile) {
    return (
      <div className={"flex flex-col gap-4"}>
        <h1>订单列表</h1>
        {data.map((i: any) => (
          <div key={i.id} className={"rounded border p-2"}>
            <div className={"flex"}>
              <div className={""}>
                <span>订单id：</span>
                <span>{i.id}</span>
              </div>
              <div className={"ml-8"}>
                <span>用户：</span>
                <span>{i.users.email}</span>
              </div>
            </div>
            <div className={"mt-2 flex"}>
              <div className={""}>
                <span>创建时间：</span>
                <span>{new Date(i.created_at).toLocaleDateString()}</span>
              </div>
              <button
                className={"ml-auto flex rounded border px-2 py-1"}
                onClick={() => toDetail(i.id)}
              >
                <span>查看详情</span>
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <TableWithData
      columns={columns}
      dataSourceUrl="/product_order/admin"
      detailUrl="/detail"
      title="订单"
    />
  );
};

export default ProductOrder;
