import { Button } from "antd";
import React, { useState } from "react";
import * as XLSX from "xlsx";

const ExcelUploader = ({onChange}) => {
  const [data, setData] = useState([]);

  // 处理文件上传
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setData(jsonData); // 将解析的数据保存到 state 中
      onChange(jsonData);
    };
    reader.readAsBinaryString(file);
  };

  // 下载模板
  const downloadTemplate = () => {
    const templateFields = [
      "备件编号",
      "备件号",
      "参考号码",
      "备件中文名称",
      "备件名称",
      "库存位置",
      "单位",
      "说明",
      "原厂价",
      "供应商",
      "进货价",
      "销售A价",
      "销售B价",
      "销售C价",
      "$UpdatedBy",
      "$Revisions",
    ];
    const templateData = [templateFields];
    const ws = XLSX.utils.aoa_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "template.xlsx");
  };

  return (
    <div className="py-4">
      <div className="flex items-center gap-4">
        <p>先下载模板后，根据字段修改数据，然后上传</p>
        <Button onClick={downloadTemplate} type="primary">
          下载模板
        </Button>
      </div>
      <hr className="my-4" />
      <div className="flex items-center gap-4">
        <p>上传 Excel 文件</p>
        <input
          id="file-input"
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
        />
      </div>

      {data.length > 0 && (
        <div>
          <h3 className="mb-2 text-lg font-semibold">解析数据：</h3>
          <table className="w-full table-auto border-collapse border border-gray-400 text-sm">
            <thead>
              <tr>
                {Object.keys(data[0]).map((key) => (
                  <th
                    key={key}
                    className="border border-gray-400 bg-gray-100 px-4 py-2"
                  >
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, colIndex) => (
                    <td
                      key={colIndex}
                      className="border border-gray-400 px-4 py-2 text-center"
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ExcelUploader;
