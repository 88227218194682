import { Select } from "antd";

export default function SelectField(props: {
  options: {
    label: string;
    value: string;
  }[];
  label: string;
  name: string;
  defaultValue: string;
  onSelect: any;
}) {
  return (
    <div
      className={"flex items-center border-b border-black p-1"}
      style={{ width: "46%" }}
    >
      <span className={"w-32"}>{props.label}：</span>
      <Select
        defaultValue={props.defaultValue}
        style={{ width: 120 }}
        onSelect={props.onSelect}
        options={props.options}
      />
    </div>
  );
}
